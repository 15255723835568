import { io } from 'socket.io-client'
import { Subject } from 'rxjs'
import { scan } from 'rxjs/operators'

import { DownloadJob, DownloadJobUpdate, UPDATE_JOBS_EVENT } from '../common/downloads'

const updateSubject = new Subject<DownloadJobUpdate[]>()

export function connectSocket(): () => void {
  const socket = io()
  socket.on(UPDATE_JOBS_EVENT, (updates: string) => updateSubject.next(JSON.parse(updates)))
  return () => socket.disconnect()
}

export const jobs$ = updateSubject.pipe(
  scan<DownloadJobUpdate[], DownloadJob[]>((prev, updates) => {
    if (prev.length === 0) {
      return updates as DownloadJob[]
    }

    const updatesById = new Map(updates.map(update => [update.id, update]))

    return [
      ...prev.map(job => {
        const update = updatesById.get(job.id)
        if (update) {
          updatesById.delete(job.id)
          return {
            ...job,
            ...update
          }
        } else {
          return job
        }

      }),
      ...updatesById.values() as Iterable<DownloadJob>
    ].sort((a, b) => a.timestamp === b.timestamp ? 0 : (a.timestamp > b.timestamp ? 1 : -1))
  }, [])
)
