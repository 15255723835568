import React, {
  ChangeEventHandler,
  Dispatch,
  FocusEventHandler,
  useCallback,
  KeyboardEvent
} from 'react'
import { useDependantState, useUpdatedRef } from '@spicy-hooks/core'
import { TextField } from '@material-ui/core'
import { TextFieldProps } from '@material-ui/core'

export type NumberFieldProps = Partial<TextFieldProps> & {
  value: number
  onChangeValue: Dispatch<number>
  min?: number
  max?: number
}

function parseValidValue (rawValue: string, minValue: number, maxValue: number): number | null {
  if (!/^[0-9]+$/.test(rawValue)) {
    return null
  }
  const value = parseInt(rawValue)
  return value >= minValue && value <= maxValue ? value : null
}

export function NumberField (
  {
    value,
    onChangeValue,
    onBlur,
    onChange,
    ...rest
  }: NumberFieldProps
) {
  const {
    min = -Infinity,
    max = Infinity
  } = rest
  const [interimInputValue, setInterimInputValue] = useDependantState(() => String(value), [value])
  const handleInputChange: ChangeEventHandler<HTMLInputElement> = useCallback(
    event => {
      const rawValue = event.target.value
      setInterimInputValue(rawValue)
      const value = parseValidValue(rawValue, min, max)
      if (value != null) {
        onChangeValue(Number(rawValue))
      }
      onChange?.(event)
    },
    [onChange, onChangeValue, setInterimInputValue, min, max]
  )

  const valueRef = useUpdatedRef(value)
  const handleInputBlur: FocusEventHandler<HTMLInputElement> = useCallback(
    event => {
      setInterimInputValue(String(valueRef.current))
      onBlur?.(event)
    },
    [valueRef, setInterimInputValue, onBlur]
  )

  const handleKeyDown = useCallback((e: KeyboardEvent) => {
    const { key, target, shiftKey } = e
    const value = parseValidValue((target as HTMLInputElement).value, min, max)

    if (value == null) return
    if (key === 'ArrowDown') {
      e.preventDefault()
      onChangeValue(Math.max(shiftKey ? value - 10 : value - 1, min))
    }
    if (key === 'ArrowUp') {
      e.preventDefault()
      onChangeValue(Math.min(shiftKey ? value + 10 : value + 1, max))
    }
  }, [min, max, onChangeValue])

  const handleInputFocus: FocusEventHandler<HTMLInputElement> = useCallback(event => {
    event.target.select()
  }, [])

  return (
    <TextField
      type="number"
      className="NumberField"
      onChange={handleInputChange}
      value={interimInputValue}
      onBlur={handleInputBlur}
      onFocus={handleInputFocus}
      onKeyDown={handleKeyDown}
      {...rest}
    />
  )
}
