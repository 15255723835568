import React, {
  ChangeEventHandler,
  Dispatch,
  MouseEventHandler,
  SetStateAction,
  useCallback,
  useMemo,
  useState
} from 'react'
import { from } from 'rxjs'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup
} from '@material-ui/core'
import { useProperty, useUpdatedRef } from '@spicy-hooks/core'
import { SnapshotState, useSnapshot } from '@spicy-hooks/observables'

import { MovieForm } from './MovieForm'
import { TvShowForm } from './TvShowForm'
import { SourceForm } from './SourceForm'
import { Movie, TvShow, Video, VideoType } from '../common/video'
import { useAuthenticatedFetchJson } from './use-authenticated-fetch-json'

export interface VideoProps {
  video: Video
  onChange: Dispatch<SetStateAction<Video>>
  onClose?: () => void
}

export function convertVideo (originalVideo: Video, newType: VideoType): Video {
  if (originalVideo.type === newType) {
    return originalVideo
  }
  const {
    name, source
  } = originalVideo
  switch (newType) {
    case VideoType.MOVIE:
      return {
        type: VideoType.MOVIE,
        name,
        source,
        year: 2000
      }
    case VideoType.TV_SHOW:
      return {
        type: VideoType.TV_SHOW,
        name,
        source,
        seriesNumber: 1,
        episodeNumber: 1
      }
  }
}

export function VideoForm ({ onChange, video, onClose }: VideoProps) {
  const [validated, setValidated] = useState(false)
  const handleChange: ChangeEventHandler<HTMLInputElement> = useCallback(
    event => onChange(prev => convertVideo(prev, event.target.value as VideoType)),
    [onChange]
  )
  const [source, setSource] = useProperty(video, onChange, 'source')

  const [schedulePromise, setSchedulePromise] = useState<Promise<void>>()
  const schedule$ = useMemo(() => schedulePromise && from(schedulePromise), [schedulePromise])
  const [, scheduleState, scheduleError] = useSnapshot(schedule$)

  const videoRef = useUpdatedRef(video)

  const authenticatedFetchJson = useAuthenticatedFetchJson()

  const onCloseRef = useUpdatedRef(onClose)

  const handleDownload: MouseEventHandler<HTMLButtonElement> = useCallback(async () => {
    const currentVideo = videoRef.current
    if (currentVideo.name.trim() === '' || currentVideo.source.url.trim() === '') {
      setValidated(true)
      return
    }

    const promise = authenticatedFetchJson('/api/downloads', {
      method: 'post',
      body: JSON.stringify(currentVideo),
      headers: {
        'Content-Type': 'application/json'
      }
    })
    setSchedulePromise(promise)
    await promise
    onChange(({ source, ...rest }) => ({
      ...rest,
      source: {
        ...source,
        url: ''
      }
    }))
    onCloseRef.current?.()
  }, [setSchedulePromise, videoRef, onChange, authenticatedFetchJson, onCloseRef])

  const handleDismissDialog = useCallback(() => setSchedulePromise(undefined), [setSchedulePromise])

  return (
    <div className="VideoForm">
      <FormControl component="fieldset">
        <FormLabel component="legend">Typ videa</FormLabel>
        <RadioGroup value={video.type} onChange={handleChange}>
          <FormControlLabel value={VideoType.MOVIE} control={<Radio/>} label="Film"/>
          <FormControlLabel value={VideoType.TV_SHOW} control={<Radio/>} label="Seriál"/>
        </RadioGroup>
      </FormControl>
      {
        video.type === VideoType.MOVIE &&
        <MovieForm movie={video as Movie} onChange={onChange as Dispatch<SetStateAction<Movie>>} validated={validated}/>
      }
      {
        video.type === VideoType.TV_SHOW &&
        <TvShowForm tvShow={video as TvShow} onChange={onChange as Dispatch<SetStateAction<TvShow>>} validated={validated}/>
      }
      <div>
        <SourceForm source={source} onChange={setSource} validated={validated}/>
      </div>
      <div className="ButtonPanel">
        {onClose && <Button onClick={onClose} color="secondary">Zavřít</Button>}
        <Button onClick={handleDownload} disabled={scheduleState === SnapshotState.WAITING} variant="contained">Přidat do knihovny</Button>
      </div>
      <Dialog
        open={scheduleState === SnapshotState.FAILED}
        onClose={handleDismissDialog}
      >
        <DialogTitle>Nastala chyba!</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Při řazení vašeho požadavku do fronty došlo k chybě.
            <pre>{scheduleError?.message ?? String(scheduleError)}</pre>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDismissDialog} color="primary">
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}
