export enum VideoType {
  MOVIE = 'MOVIE',
  TV_SHOW = 'TV_SHOW'
}

export enum SourceType {
  ULOZTO = 'ULOZTO'
}

export interface UloztoSource {
  type: SourceType.ULOZTO,
  url: string
}

export type Source = UloztoSource

export interface Movie {
  type: VideoType.MOVIE
  name: string
  year: number
  source: Source
}

export interface TvShow {
  type: VideoType.TV_SHOW
  name: string
  seriesNumber: number
  episodeNumber: number
  source: Source
}

export type Video = Movie | TvShow
