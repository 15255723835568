import { useMemo } from 'react'
import { Observable, Subject } from 'rxjs'
import { useSingleton } from '@spicy-hooks/core'
import { bind, coldFrom, concurrentLatest, useSnapshot } from '@spicy-hooks/observables'
import { debounceTime, distinctUntilChanged, filter, map } from 'rxjs/operators'
import { latency } from '@spicy-hooks/utils'
import { useAuthenticatedFetchJson } from './use-authenticated-fetch-json'
import { MovieSearchResults } from '../common/movie-search'

export function useMovieSearch (query: string): Observable<MovieSearchResults> | null {
  const authenticatedFetchJson = useAuthenticatedFetchJson()
  const cache = useSingleton(() => new Map<string, MovieSearchResults>())
  const querySubject = useSingleton(() => new Subject<string>())
  querySubject.next(query)
  const results$$ = useMemo(() => querySubject.pipe(
    map(query => query.trim()),
    distinctUntilChanged(),
    filter(query => query.length >= 3),
    debounceTime(1000),
    bind(async query => {
      const cachedResults = cache.get(query)
      if (cachedResults) {
        await latency(1)
        return cachedResults
      }
      const results = await authenticatedFetchJson<MovieSearchResults>(
        `/api/search?query=${encodeURIComponent(query)}`
      )
      cache.set(query, results)
      return results
    }),
    coldFrom(),
    concurrentLatest()
  ), [querySubject, cache, authenticatedFetchJson])

  const [results$] = useSnapshot(results$$)
  return results$
}
