import { Button, LinearProgress, SnackbarContent } from '@material-ui/core'
import React, { useCallback, useState } from 'react'

import { DownloadJob } from '../common/downloads'
import { VideoType } from '../common/video'

export interface PendingDownloadProps {
  job: DownloadJob
}

export function PendingDownload ({ job }: PendingDownloadProps) {
  const [closing, setClosing] = useState(false)
  const handleClose = useCallback(async () => {
    setClosing(true)
    await fetch(`/api/downloads/${encodeURIComponent(job.id)}`, {
      method: 'delete'
    })
  },[setClosing, job.id])

  const handleRetry = useCallback(async () => {
    await fetch(`/api/downloads/${encodeURIComponent(job.id)}`, {
      method: 'put'
    })
  },[job.id])

  if (closing) {
    return null
  }

  const name = job.video?.type === VideoType.MOVIE
    ? `${job.video?.name} (${job.video?.year})`
    : `${job.video?.name} S${String(job.video?.seriesNumber).padStart(2, '0')}E${String(job.video?.episodeNumber).padStart(2, '0')}`

  const closeButton = <Button onClick={handleClose} color="secondary">╳</Button>
  switch (job.status) {
    case 'completed':
      return <SnackbarContent className="flexSnackbar"
                              message={<><span>✓ {name}</span>{closeButton}</>}/>
    case 'active':
      return (
        <SnackbarContent className="flexSnackbar" message={
          <>
            <div>
              <div>{name}</div>
              <LinearProgress
                variant={job.progress?.totalBytes != null ? 'determinate' : 'indeterminate'}
                value={job.progress?.totalBytes != null ? (job.progress.downloadedBytes / job.progress.totalBytes) * 100 : undefined}
              />
            </div>
          </>
        }/>
      )
    case 'failed':
      return <SnackbarContent className="flexSnackbar" message={
        <>
          <div>
            <div>❗ {name}</div>
            <div title={job.stacktrace?.join('\n')}>{job.failedReason}</div>
            <Button onClick={handleRetry} color="inherit">↻ Znovu</Button>
            <Button href={`errors/${job.id}.png`} target="screenshot" color="inherit">Screenshot</Button>
          </div>
          {closeButton}
        </>
      }/>
    case'paused':
      return <SnackbarContent className="flexSnackbar"
                              message={<><span>⏸ {name}</span>{closeButton}</>}/>

    default:
      return <SnackbarContent className="flexSnackbar"
                              message={<><span>🕑 {name}</span>{closeButton}</>}/>
  }
}
