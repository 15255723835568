import { createUseContext } from '@spicy-hooks/core'

export interface User {
  name: string
  imageUrl: string
  token: string
}
export const useUser = createUseContext<User | null>({
  contextName: 'User',
  defaultValue: null
})
