import React from 'react'
import { usePartialSnapshot } from '@spicy-hooks/observables'
import { Button, SnackbarContent } from '@material-ui/core'

import { jobs$ } from './jobs'
import { WEB_ORIGIN } from './config'

export function PendingDownloadCount () {
  const [jobCount] = usePartialSnapshot(jobs$, jobs => jobs.length, [])
  return jobCount != null && jobCount > 0
    ? (
      <SnackbarContent className="pendingDownloadCount" message={
        <>
          Stahuje se <strong>{jobCount}</strong> položek <Button
          color="secondary"
          href={WEB_ORIGIN}
          target="home_goce_cz"
        >detaily</Button>
        </>
      }/>
    )
    : null
}
