import { Source, SourceType } from '../common/video'
import React, { ChangeEventHandler, Dispatch, SetStateAction, useCallback } from 'react'
import { FormControl, FormControlLabel, FormLabel, Radio, RadioGroup } from '@material-ui/core'
import { useProperty } from '@spicy-hooks/core'
import { UloztoForm } from './UloztoForm'

export interface SourceFormProps {
  source: Source
  onChange: Dispatch<SetStateAction<Source>>
  validated: boolean
}

export function SourceForm ({ onChange, source, validated }: SourceFormProps) {
  const [type, setType] = useProperty(source, onChange, 'type')
  const handleChange: ChangeEventHandler<HTMLInputElement> =
    useCallback(event => setType(event.target.value as SourceType), [setType])

  return (
    <>
      <FormControl component="fieldset">
        <FormLabel component="legend">Typ zdroje</FormLabel>
        <RadioGroup value={type} onChange={handleChange}>
          <FormControlLabel value={SourceType.ULOZTO} control={<Radio/>} label="Ulož.to"/>
        </RadioGroup>
      </FormControl>
      {
        type === SourceType.ULOZTO &&
          <UloztoForm source={source} onChange={onChange} validated={validated}/>
      }
    </>
  )
}
