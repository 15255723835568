import React, { useEffect, useRef, useState } from 'react'

import { Movie, SourceType, TvShow, Video, VideoType } from '../common/video'
import { PendingDownloads } from './PendingDownloads'
import { convertVideo, VideoForm } from './VideoForm'
import { PendingDownloadCount } from './PendingDownloadCount'

const filenamePatterns: Array<[VideoType, RegExp, Array<keyof Movie | keyof TvShow>]> = [
  [VideoType.MOVIE, /^(.+)\W*\b\(?((19|20)[0-9]{2})/, ['name', 'year']],
  [VideoType.TV_SHOW, /^(.+)\bs([0-9]+)e([0-9]+)/i, ['name', 'seriesNumber', 'episodeNumber']],
  [VideoType.TV_SHOW, /^(.+)\b([0-9]+)-([0-9]+)/, ['name', 'seriesNumber', 'episodeNumber']],
  [VideoType.TV_SHOW, /^(.+)\b([0-9]+)/, ['name', 'episodeNumber']],
  [VideoType.MOVIE, /^(.+)\.(mkv|mp4|avi)/i, ['name']]
]

function guessVideo (filename: string): Partial<Video> | null {
  for (const [type, pattern, targets] of filenamePatterns) {
    const matches = pattern.exec(filename)
    if (matches) {
      const video: Partial<Video> = {
        type
      }
      targets.forEach((target, index) => {
        // @ts-ignore
        video[target] = matches[index + 1].trim().replace(/(^| +)[!-/:-@[-`{-~]*([^ ]*?)[!-/:-@[-`{-~]*(?=\s|$)/gi, '$1$2').trim()
      })
      return video
    }
  }
  return null
}

export interface DownloadQueueProps {
  onClose?: () => void
  docked: boolean
}

export function DownloadQueue ({ onClose, docked }: DownloadQueueProps) {
  const [video, setVideo] = useState<Video>({
    type: VideoType.MOVIE,
    name: '',
    year: 2000,
    source: {
      type: SourceType.ULOZTO,
      url: ''
    }
  })

  const lastGuessedName = useRef<string>()
  useEffect(
    () => {
      const handleMessage = (event: MessageEvent<{ action: string, filename: string, url: string }>) => {
        if (event.data?.action === 'prefil') {
          const { filename, url } = event.data
          const guess = guessVideo(filename)
          if(!guess) {
            setVideo(prev => ({
              ...prev,
              name: filename,
              source: {
                type: SourceType.ULOZTO,
                  url
              }
            }))
            return
          }

          const { name, type, ...rest } = guess
          setVideo(prev => {
            const next = {
              ...convertVideo(prev, type!),
              ...rest,
              source: {
                type: SourceType.ULOZTO,
                url
              }
            }
            if (name && lastGuessedName.current !== name) {
              next.name = name
              lastGuessedName.current = name
            }
            return next as Video
          })
        }
      }
      window.addEventListener('message', handleMessage)
      return () => window.removeEventListener('message', handleMessage)
    },
    [setVideo]
  )

  return (
    <div className={docked ? 'docked' : 'undocked'}>
      {docked
        ? <PendingDownloadCount/>
        : <PendingDownloads/>
      }
      <VideoForm video={video} onChange={setVideo} onClose={onClose}/>
    </div>
  )
}
