export type ChangeReturnType<T extends (...args: any[]) => any, R> = (...args: Parameters<T>) => R;
export type JsonFetch = ChangeReturnType<typeof fetch, Promise<void>>

export async function fetchJson<T> (...args: Parameters<typeof fetch>): Promise<T> {
  const response = await fetch(...args)

  if (response.ok) {
    if (response.headers.get('Content-Length') === '0' || response.status === 204) {
      return undefined as unknown as T
    } else {
      return response.json()
    }
  } else {
    throw new Error(`HTTP ${response.status} ${response.statusText}`)
  }
}
