import React from 'react'
import { useSnapshot } from '@spicy-hooks/observables'

import { jobs$ } from './jobs'
import { PendingDownload } from './PendingDownload'

export function PendingDownloads () {
  const [jobs] = useSnapshot(jobs$)

  return (
    <div className="pendingDownloads">
      {jobs?.map(job => <PendingDownload job={job} key={job.id}/>)}
    </div>
  )
}
