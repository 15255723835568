import { UloztoSource } from '../common/video'
import React, { ChangeEventHandler, Dispatch, SetStateAction, useCallback } from 'react'
import { useProperty } from '@spicy-hooks/core'
import { TextField } from '@material-ui/core'

export interface UloztoFormProps {
  source: UloztoSource
  onChange: Dispatch<SetStateAction<UloztoSource>>
  validated: boolean
}

export function UloztoForm ({ source, onChange, validated }: UloztoFormProps) {
  const [url, setUrl] = useProperty(source, onChange, 'url')
  const handleUrlChange: ChangeEventHandler<HTMLInputElement> =
    useCallback(event => setUrl(event.target.value), [setUrl])
  return (
    <TextField label="URL adresa položky" value={url} onChange={handleUrlChange}
      error={url.trim() === '' && validated}
    />
  )
}
