import React, { ChangeEventHandler, Dispatch, SetStateAction, useCallback } from 'react'
import { TextField } from '@material-ui/core'
import { useProperty } from '@spicy-hooks/core'

import { TvShow } from '../common/video'
import { NumberField } from './NumberField'

export interface TvShowFormProps {
  tvShow: TvShow
  onChange: Dispatch<SetStateAction<TvShow>>
  validated: boolean
}

export function TvShowForm ({ tvShow, onChange, validated }: TvShowFormProps) {
  const [name, setName] = useProperty(tvShow, onChange, 'name')
  const [seriesNumber, setSeriesNumber] = useProperty(tvShow, onChange, 'seriesNumber')
  const [episodeNumber, setEpisodeNumber] = useProperty(tvShow, onChange, 'episodeNumber')

  const handleNameChange: ChangeEventHandler<HTMLInputElement> =
    useCallback(event => setName(event.target.value), [setName])

  return (
    <>
      <TextField label="Anglický název seriálu" value={name} onChange={handleNameChange} error={name.trim() === '' && validated}/>
      <NumberField label="Série" value={seriesNumber} onChangeValue={setSeriesNumber}/>
      <NumberField label="Epizoda" value={episodeNumber} onChangeValue={setEpisodeNumber}/>
    </>
  )
}
