import { JobStatus } from 'bull'

import { Video } from './video'

export interface DownloadProgress {
  downloadedBytes: number
  totalBytes: number | null
}


export interface DownloadJob {
  id: string
  progress: DownloadProgress | null
  video: Video
  failedReason: string | null
  stacktrace: string[]
  status: JobStatus
  timestamp: number
}

export interface DownloadJobUpdate {
  id: string
  progress?: DownloadProgress | null
  video?: Video
  failedReason?: string | null
  stacktrace?: string[]
  status?: JobStatus
}

export const UPDATE_JOBS_EVENT = 'updateJobs'
