import React, { useMemo } from 'react'

export interface BookmarkletProps {
  origin: string
}

export function Bookmarklet ({ origin }: BookmarkletProps) {
  const href = useMemo(
    () => {
      const script = `(function () {
    var script = document.createElement('script');
    window.GOCE_WEB_ORIGIN = '${origin}';
    script.src = '${origin}/integrations/ulozto.js?rnd=' + Date.now();
    document.body.appendChild(script);
})();`

      return `javascript:${encodeURIComponent(script.replace(/\s+/g, ' '))}`
    },
    [origin]
  )

  return (
    <p className='bookmarkletContainer'>
      Pro plnou integraci do portálu Ulož.to, prosím přidejte následující odkaz mezi své záložky: <a href={href}>Stáhnout do Plex</a>
    </p>
  )
}
