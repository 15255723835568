import { useUser } from './use-user'
import { useCallback } from 'react'
import { fetchJson } from './fetch-json'

export function useAuthenticatedFetchJson (): <T = void>(input: RequestInfo, init?: RequestInit) => Promise<T> {
  const { token } = useUser() ?? {}
  return useCallback((input, init) => {
    return fetchJson(
      input,
      token
        ? {
          ...init,
          headers: {
            ...init?.headers,
            'Authorization': `Bearer ${token}`
          }
        }
        : init
    )
  }, [token])
}
