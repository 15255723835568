import { Movie } from '../common/video'
import React, { ChangeEventHandler, Dispatch, SetStateAction, useCallback } from 'react'
import { TextField } from '@material-ui/core'
import { useLastAvailable, useProperty } from '@spicy-hooks/core'
import { useMovieSearch } from './use-movie-search'
import { useSnapshot } from '@spicy-hooks/observables'
import { NumberField } from './NumberField'

export interface MovieFormProps {
  movie: Movie
  onChange: Dispatch<SetStateAction<Movie>>
  validated: boolean
}

export function MovieForm ({ movie, onChange, validated }: MovieFormProps) {
  const results$ = useMovieSearch(movie.name)
  const [results] = useSnapshot(results$)
  const lastResults = useLastAvailable(results)

  const [name, setName] = useProperty(movie, onChange, 'name')
  const handleNameChange: ChangeEventHandler<HTMLInputElement> =
    useCallback(event => {
      const value = event.target.value
      setName(value)
    }, [setName])
  const [year, setYear] = useProperty(movie, onChange, 'year')

  return (
    <>
      <div>
        <TextField
          label="Anglický název filmu"
          value={name} onChange={handleNameChange}
          helperText="napiště část českého nebo anglického názvu pro vyhledání"
          error={name.trim() === '' && validated}
        />
        <div className="searchResults">
          {
            lastResults?.results.slice(0, 5).map(result => {
              const yearString = result.release_date?.replace(/-.+$/, '')
              const year = yearString && Number(yearString)
              return (
                <button className={`searchResult ${result.poster_path ? 'withPoster' : ''}`} key={result.id}
                        onClick={() => {
                          setName(result.title)
                          if (year) {
                            setYear(year)
                  }
                }}>
                  {result.poster_path && <img src={`https://image.tmdb.org/t/p/w92${result.poster_path}`} alt=""/>}
                  <span className="info">
                  <span className="title">{result.title}</span>
                  <span className="year">{year}</span>
                </span>
                </button>
              )
            })
          }
        </div>
      </div>
      <NumberField label="Rok vzniku" value={year} type="number" onChangeValue={setYear}/>
    </>
  )
}
