import { FunctionComponent, useCallback, useEffect, useMemo, useState } from 'react'
import { AppBar, Avatar, Button, Toolbar, Typography } from '@material-ui/core'

import { DownloadQueue } from './DownloadQueue'
import { User, useUser } from './use-user'
import GoogleLogin, {
  GoogleLoginProps,
  GoogleLoginResponse,
  GoogleLoginResponseOffline,
  useGoogleLogout
} from 'react-google-login'
import { GOOGLE_CLIENT_ID, WEB_ORIGIN } from './config'
import { connectSocket } from './jobs'
import { Bookmarklet } from './Bookmarklet'

const RetypedGoogleLogin = GoogleLogin as any as FunctionComponent<GoogleLoginProps>

export function App () {
  const [hostWindow, setHostWindow] = useState<WindowProxy | null>(null)

  const [user, setUser] = useState<User | null>(null)
  const handleLogin: (response: GoogleLoginResponse | GoogleLoginResponseOffline) => void = useCallback(
    response => {
      if ('getAuthResponse' in response) {
        const profile = response.getBasicProfile()
        const authResponse = response.getAuthResponse()
        setUser({
          imageUrl: profile.getImageUrl(),
          name: profile.getName(),
          token: authResponse.id_token
        })
      } else {
        throw new Error('Offline access not allowed')
      }
    },
    [setUser]
  )

  useEffect(
    () => {
      if (!user?.token) {
        return
      }

      return connectSocket()
    },
    [user?.token]
  )

  useEffect(
    () => {
      const handleMessage = (event: MessageEvent) => {
        if(event.data?.action === 'dock') {
          setHostWindow(event.source as WindowProxy)
        }
      }
      window.addEventListener('message', handleMessage)
      return () => window.removeEventListener('message', handleMessage)

    },
    [user?.token]
  )

  const handleClose = useMemo(() => hostWindow
    ? () => hostWindow?.postMessage({ action: 'close' }, '*')
    : undefined,
    [hostWindow]
  )

  const handleLogout = useCallback(() => setUser(null), [setUser])

  const { signOut } = useGoogleLogout({
    clientId: GOOGLE_CLIENT_ID,
    onLogoutSuccess: handleLogout
  })

  const handleFailure = useCallback((error: any) => {
    console.error(error)
    alert(error.message ?? String(error))
  }, [])

  return (
    <>
      {!hostWindow &&
      <AppBar position="static">
        <Toolbar>
          <Typography variant="h6" className="AppTitle">
            Plex - stahování
          </Typography>
          {
            user &&
            <>
              <Avatar src={user.imageUrl} title={user.name}/>
              <Button color="inherit" onClick={() => signOut()}>Odhlásit</Button>
            </>
          }
        </Toolbar>
      </AppBar>
      }
      {
        user
          ? (
            <useUser.Provider value={user}>
              <DownloadQueue onClose={handleClose} docked={!!hostWindow}/>
              {!hostWindow && <Bookmarklet origin={WEB_ORIGIN}/>}
            </useUser.Provider>
          )
          : (
            <RetypedGoogleLogin
              clientId={GOOGLE_CLIENT_ID}
              onSuccess={handleLogin}
              onFailure={handleFailure}
              className="LoginButton"
              isSignedIn
            />
          )
      }
    </>
  )
}
